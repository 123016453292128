import React from 'react';
import { Input, Select } from 'components/Form';
import { ButtonX } from 'components/base';

const ProductPrice = ({ price, onChange, onDelete, factories }) => {
   return (
      <div className="flex justify-between flex-1 relative">
         <div>
            <div className="flex items-center">
               <label className="mr-4">Supplier: </label>
               <Select
                  value={price.factoryId}
                  onChange={(e) => onChange('factoryId', e.target.value)}
               >
                  <option value="0">--- Choose Factory ---</option>
                  {factories.map((factory) => (
                     <option key={factory.id} value={factory.id}>
                        {factory.name}
                     </option>
                  ))}
               </Select>
            </div>
            <div className="flex items-center mt-2">
               <div className="flex items-center">
                  <label className="mr-4">Min Qty: </label>
                  <Input
                     className="text-center"
                     value={price.minQty}
                     onChange={(e) => onChange('minQty', e.target.value)}
                  />
               </div>
               <div className="ml-4 flex items-center">
                  <label className="mr-4">Price / Unit: </label>
                  <Input
                     className="text-center"
                     value={price.price}
                     onChange={(e) => onChange('price', e.target.value)}
                  />
               </div>
               <div className="ml-4">
                  <Select
                     value={price.currency}
                     onChange={(e) => onChange('currency', e.target.value)}
                  >
                     <option value="USD">USD</option>
                     <option value="SEK">SEK</option>
                     <option value="RMB">RMB</option>
                  </Select>
               </div>
            </div>

            <div className="flex items-center mt-2">
               <div className="flex items-center">
                  <label htmlFor="">Available from month</label>
                  <Select
                     className="mx-3"
                     onChange={(e) =>
                        onChange('avaliableFromMonth', e.target.value)
                     }
                     value={price.avaliableFromMonth}
                  >
                     {[...Array(12).keys()].map((i) => (
                        <option value={i + 1} key={i}>
                           {i + 1}
                        </option>
                     ))}
                  </Select>
                  <label htmlFor="">to</label>
                  <Select
                     className="mx-3"
                     onChange={(e) =>
                        onChange('avaliableToMonth', e.target.value)
                     }
                     value={price.avaliableToMonth}
                  >
                     {[...Array(12).keys()].map((i) => (
                        <option value={i + 1} key={i}>
                           {i + 1}
                        </option>
                     ))}
                  </Select>
               </div>
            </div>
         </div>

         <div className="flex items-center text-2xl">
            <ButtonX onClick={onDelete} />
         </div>
      </div>
   );
};

export default ProductPrice;
