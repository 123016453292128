import CharlesButton from "components/charles/base";
import { useState } from "react";

const FilterStockProductsView = ({
  products,
  initialProductIds,
  onSelectProductIds,
  hide,
}) => {
  const [state, setState] = useState(initialProductIds);

  const okHandler = () => {
    onSelectProductIds(state);
    hide();
  };

  return (
    <div>
      <div className="space-y-2">
        {products.map((product) => (
          <div
            key={product.id}
            className=" cursor-pointer flex items-center space-x-2"
          >
            <input
              id={`product-id-${product.id}`}
              type="checkbox"
              checked={state.includes(product.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setState([...state, product.id]);
                } else {
                  setState(state.filter((id) => id !== product.id));
                }
              }}
            />
            <label
              className=" cursor-pointer"
              htmlFor={`product-id-${product.id}`}
            >
              [{product.number}] {product.name}
            </label>
          </div>
        ))}
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={okHandler}>
          OK
        </CharlesButton>
      </div>
    </div>
  );
};

export default FilterStockProductsView;
