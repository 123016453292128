import { useMutation, useQuery } from '@apollo/client';
import Spinner from 'components/Spinner.js';
import Errors from 'components/Errors.js';
import { CREATE_STOCK, FETCH_CHINA_STOCKS } from './graphql';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from 'components/SearchBar';
import { useState } from 'react';
import { searchByProp } from 'utils/search';
import CharlesButton from 'components/charles/base';
import { Alert } from 'components/Toast';
import { parseError } from 'apollo';

function ChinaStocks() {
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCKS);
  const [createStock, createStockRes] = useMutation(CREATE_STOCK, {
    refetchQueries: [{ query: FETCH_CHINA_STOCKS }],
    onCompleted(res) {
      Alert('success', 'Stock is created');
      console.log('res', res);
      navigate(`${res.createStock.stock.id}`);
    },
    onError(error) {
      Alert('error', parseError(error));
    },
  });
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  const filteredData = data.odooStocks
    .map((i) => {
      const combined =
        i.name + ' - ' + i.products.map((i) => i.number).join(', ');
      return { ...i, combined };
    })
    .filter((i) => searchByProp(i, ['combined'], searchText));

  function tryAddProductToStock() {
    const name = window.prompt('Input the name of the stock category:');
    if (!name) return;
    createStock({ variables: { name } });
  }

  return (
    <div className="p-6">
      <div className="card p-4 animate-fadeInDown">
        <div className="flex justify-between place-items-center space-x-6">
          <SearchBar
            containerClassName="flex-1"
            placeholder="Search by name"
            value={searchText}
            onChange={setSearchText}
          />
          <CharlesButton
            onClick={tryAddProductToStock}
            loading={createStockRes.loading}
          >
            + Add New Stock
          </CharlesButton>
        </div>
        <div className="mt-1">
          <table>
            <tbody className="divide-y dark:divide-gray-700">
              {filteredData.map((stock, index) => (
                <tr
                  key={index}
                  className=" animate-fadeInDown opacity-0"
                  style={{
                    animationDelay: `${index * 0.01}s`,
                  }}
                >
                  <td className=" whitespace-nowrap">
                    <Link to={stock.id}>{stock.name}</Link>
                  </td>
                  <td>
                    <div className="mt-1 opacity-70 text-xs">
                      {stock.products.map((i) => i.number).join(', ')}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ChinaStocks;
