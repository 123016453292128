import { byName } from "utils/sort";

const LumpSumSupplierDetail = ({ supplier }) => {
  const products = [...supplier.products].sort(byName);

  return (
    <div className="text-sm">
      <div className="italic opacity-70 text-xs">
        Products to produce from Order Scheduler plans whose start date is between your selected dates, only Plan in 'Waiting to Confirm' and 'Planing' status
        are included.
      </div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right">Total QTY</th>
            </tr>
          </thead>
          <tbody>
            {products.map((i) => (
              <tr key={i.id} className="border-b">
                <td>
                  <div>
                    [{i.number}] {i.name}
                  </div>
                  <div className="text-xs opacity-70">Plans: {i.plans.map((plan) => plan.name).join(", ")}</div>
                </td>
                <td className="text-right">{i.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LumpSumSupplierDetail;
