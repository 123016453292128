// import { gql, useMutation, useQuery } from "@apollo/client";
// import Spinner from "components/Spinner";
// import Errors from "components/Errors";
// import { Fragment, useState } from "react";
// import { formatDate } from "react-day-picker/moment";
// import { DatePicker } from "components/Form";
// import { Button, ButtonWithArrow } from "components/base";
// import { CREATE_PRODUCTION_PLAN } from "./graphql";
// import { Alert } from "components/Toast";
// import moment from "moment";
// import { useModals } from "ModalProvider";
// import { BsArrowRight } from "react-icons/bs";
// import { IoIosCube } from "react-icons/io";
// import { VscSourceControl } from "react-icons/vsc";
// import NodeView from "./NodeView";

// const COMPUTE_PRODUCTION_PLAN = gql`
//   query COMPUTE_PRODUCTION_PLAN($orders: [PlanOrderInputType], $lines: [PlanProductionLineInputType], $startDate: DateTime!) {
//     result: computeProductionPlan(orders: $orders, lines: $lines, startDate: $startDate)
//   }
// `;

// function computeReadyDate(odooOrders) {
//   if (odooOrders.length === 0) return new Date().addDays(7);
//   const orderDates = odooOrders.map((i) => moment(i.date_order).toDate().addDays(7));
//   const maxDate = new Date(Math.max.apply(null, [...orderDates, new Date()]));
//   return maxDate;
// }

// const OrderScheduler = ({ odooOrders, onCreatedPlan }) => {
//   const [startDate, setStartDate] = useState(computeReadyDate(odooOrders));
//   const { loading, error, data } = useQuery(COMPUTE_PRODUCTION_PLAN, {
//     fetchPolicy: "network-only",
//     variables: {
//       orders: odooOrders.map((i) => ({ id: i.id, type: i.type })),
//       startDate,
//     },
//   });

//   const [createProductionPlan, createProductionPlanRes] = useMutation(CREATE_PRODUCTION_PLAN, {
//     onCompleted(res) {
//       Alert("success", "Plan Saved.");
//       onCreatedPlan(res.createProductionPlan.productionPlan);
//     },
//     onError(error) {
//       Alert("error", error.message);
//     },
//     awaitRefetchQueries: true,
//     refetchQueries: ["FETCH_PREV_PLANS"],
//   });

//   function savePlan(data) {
//     const name = odooOrders.map((i) => i.name).join("/");
//     const customer = odooOrders.length > 0 ? odooOrders[0].partnerName : null;
//     createProductionPlan({
//       variables: {
//         name,
//         customer,
//         startDate,
//         data,
//         planType: "ORDER",
//         odooObjects: odooOrders.map((i) => ({
//           objectId: i.id,
//           objectName: i.name,
//           objectType: i.type,
//         })),
//       },
//     });
//   }

//   return (
//     <div className="space-y-8 p-6 flex flex-col flex-1">
//       <div className="flex space-x-4 items-center relative z-50">
//         <label htmlFor="">Start Date: </label>
//         <DatePicker value={startDate} onDayChange={setStartDate} />
//       </div>

//       <div className="relative flex-1">
//         {loading ? (
//           <Spinner text="Computing, this might take a few seconds..." />
//         ) : error ? (
//           <Errors error={error} />
//         ) : (
//           <>
//             <PlanResult result={data.result} />
//             <Button
//               className="mt-8 mx-2"
//               loading={createProductionPlanRes.loading}
//               disabled={createProductionPlanRes.loading}
//               size="lg"
//               bold
//               title="Save Plan"
//               onClick={() => savePlan(data.result)}
//             />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export const PlanResult = ({ result }) => {
//   console.log("JSON.parse(result)", JSON.parse(result));
//   const { productions } = JSON.parse(result);

//   return (
//     <div>
//       <div className="mt-8">
//         <Productions productions={productions} />
//       </div>
//     </div>
//   );
// };

// const ExtraComputeString = ({ type, value }) => {
//   if (type === "FIX_DAYS") return `${value} days.`;
//   if (type === "QTY_PER_DAY") return `${value} / day.`;

//   return "Invalid type.";
// };

// export const Productions = ({ productions }) => {
//   const nodeModal = useModals();

//   function showNode(nodeIds) {
//     nodeModal.present({
//       title: "Node",
//       maxWidth: "max-w-6xl",
//       children: <NodeView nodeIds={nodeIds} />,
//     });
//   }

//   return (
//     <div>
//       <h4>Inventory, Purchase and Manufacturing</h4>

//       <div className="space-y-4 mt-4">
//         {productions.length > 0 ? (
//           <table>
//             <thead>
//               <tr className="whitespace-nowrap">
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-left">Production</th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">Request QTY</th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">Forecasted</th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">
//                   Prev Plans Consumed
//                 </th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">Using Stock</th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">To Consumed</th>
//                 <th className="py-3 z-10 sticky top-0 backdrop-blur bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-90 text-right">To Produce</th>
//               </tr>
//             </thead>
//             <tbody>
//               {productions.map((production, index) => (
//                 <ProductionSection key={index} production={production} showNode={showNode} />
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <div>No purchase or manufacturing. Make sure each product in orders has correct bom and production nodes.</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export const ProductionSection = ({ production, showNode }) => {
//   const [showPrevPos, setShowPrevPos] = useState(false);

//   console.log("production", production);

//   return (
//     <Fragment>
//       <tr>
//         <td
//           colSpan={7}
//           className={`p-2
//                   ${production.production_time < 0 ? "bg-pink-200 text-pink-600" : "bg-gray-100 dark:bg-gray-900 dark:bg-opacity-80"}
//                `}
//         >
//           <div>
//             <div className="flex items-center space-x-3">
//               <div className="font-semibold">
//                 {production.node.category.name} - {production.node.category.supplier.name}{" "}
//                 {production.node.category.time_string
//                   ? `[
//                      ${production.node.category.time_string}]`
//                   : null}{" "}
//                 &times; {production.qty}
//                 {production.production_time < 0 ? " - Warning: Qty overflow!!" : null}
//               </div>

//               {production.node ? <Button leftIcon={<VscSourceControl />} onClick={() => showNode(production.node)} /> : null}
//             </div>

//             {production.node.category.extra_computings && production.node.category.extra_computings.length > 0 ? (
//               <div>
//                 <label className="pr-2">Exra Computings:</label>
//                 {production.node.category.extra_computings.map((i) => (
//                   <span key={i.id} className="mr-2">
//                     <span>{i.name}: </span>
//                     <span>
//                       <ExtraComputeString type={i.type} value={i.value} />
//                     </span>
//                   </span>
//                 ))}
//               </div>
//             ) : null}
//           </div>
//         </td>
//       </tr>

//       {production.items.map((item, itemIndex) => (
//         <tr key={itemIndex}>
//           <td className="px-2 py-1">
//             <div className="space-x-2 flex items-center">
//               <span>
//                 [{item.number}] {item.name}{" "}
//               </span>
//               {item.has_stock ? <IoIosCube className="text-green-600 text-base" /> : null}

//               {item.supplier_stock ? (
//                 <div className="flex items-center space-x-2">
//                   <IoIosCube className="text-orange-600 text-base" />
//                   <span>{item.supplier_stock.latest_qty}</span>
//                   {item.supplier_stock.ready_date && item.supplier_stock.latest_qty > 0 ? (
//                     <span>[{moment(item.supplier_stock.ready_date * 1000).format("YYYY-MM-DD")}]</span>
//                   ) : null}
//                 </div>
//               ) : null}
//             </div>
//           </td>
//           <td className="text-right px-2 py-1">{item.qty}</td>
//           <td className="text-right px-2 py-1  whitespace-nowrap">
//             {item.latest_batch_po ? (
//               <div>
//                 <span className="pr-1 opacity-50 scale-75">{item.latest_batch_po.name}</span>
//                 <span>{item.latest_batch_po.left}</span>
//               </div>
//             ) : (
//               0
//             )}
//           </td>
//           <td className="text-right px-2 py-1 whitespace-nowrap">
//             {item.prev_plan_consumed && item.prev_plan_consumed.length > 0 ? item.prev_plan_consumed.reduce((res, i) => res + i.qty, 0) : 0}
//           </td>
//           <td className="text-right px-2 py-1 whitespace-nowrap">
//             {item.to_consume > 0 && item.using_batch_po ? <span className="pr-1 text-xs opacity-50 scale-75">{item.using_batch_po.name}</span> : null}{" "}
//             {item.left}
//           </td>
//           <td className="text-right px-2 py-1">{item.to_consume}</td>
//           <td className="text-right px-2 py-1">{item.to_produce}</td>
//         </tr>
//       ))}

//       <tr>
//         <td className="p-2 pb-4" colSpan={7}>
//           <div className="flex items-baseline space-x-4">
//             <div>
//               {production.prev_batch ? (
//                 <div className="text-green-600 font-semibold">
//                   Use Previous {production.prev_batch.name} {formatDate(production.prev_batch.date * 1000, "YYYY-MM-DD")}
//                 </div>
//               ) : null}

//               <div className="flex items-baseline space-x-2">
//                 <div className="text-teal-600 font-semibold text-base flex items-center space-x-2">
//                   <span>{formatDate(production.start_date * 1000, "YYYY-MM-DD")}</span>
//                   <BsArrowRight />
//                   <span>{formatDate(production.ready_date * 1000, "YYYY-MM-DD")} </span>
//                 </div>
//               </div>

//               {production.qty > 0 ? (
//                 <div className="space-x-2 opacity-50">
//                   <span>Production: {production.production_time} days</span>
//                   {production.transfer_time > 0 ? (
//                     <>
//                       <span>+</span>
//                       <span>Transfer: {production.transfer_time} days</span>
//                       <span>=</span>
//                       <span>{production.total_days} days</span>
//                     </>
//                   ) : null}
//                 </div>
//               ) : null}
//             </div>

//             {production.prev_pos ? (
//               <ButtonWithArrow className="text-sky-600" show={showPrevPos} onClick={() => setShowPrevPos((prev) => !prev)}>
//                 {showPrevPos ? "Hide" : "Show"} Current Production
//               </ButtonWithArrow>
//             ) : null}
//           </div>
//         </td>
//       </tr>

//       {showPrevPos ? (
//         <tr>
//           <td colSpan={6}>
//             <div className="flex">
//               <div className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50 rounded-2xl p-4 mb-4">
//                 <div className="px-2">Total qty in PO not confirmed for this production category by month.</div>
//                 <div className="flex">
//                   <div className="mt-2">
//                     {production.prev_pos.map((po) => (
//                       <div key={po.id} className="text-xs opacity-70 flex items-center justify-between border-b dark:border-gray-700 space-x-8">
//                         <div className="px-2 py-1 ">
//                           {po.year} - {po.month}
//                         </div>

//                         <div className="px-2 py-1">{po.assignedQty}</div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </td>
//         </tr>
//       ) : null}
//     </Fragment>
//   );
// };

// const OrderLine = ({ editProduct, line }) => {
//   const [showBom, setShowBom] = useState(false);

//   return (
//     <>
//       <tr key={line.id}>
//         <td>
//           <div className="flex items-center space-x-4">
//             <div>
//               {line.name}
//               {line.product_id ? null : <span className="text-red-500 font-semibold pl-4">No Odoo Product.</span>}
//             </div>

//             {line.product_id ? (
//               line.wis_product ? (
//                 <>
//                   {line.wis_product.has_production ? null : <div className="text-red-500 font-semibold">No production.</div>}

//                   {line.product_bom ? (
//                     <ButtonWithArrow className="text-sky-600 text-xs whitespace-nowrap" show={showBom} onClick={() => setShowBom((prev) => !prev)}>
//                       {showBom ? "hide bom" : "show bom"}
//                     </ButtonWithArrow>
//                   ) : null}
//                 </>
//               ) : (
//                 <div className="text-red-500 font-semibold">Not connected with wis.</div>
//               )
//             ) : null}

//             {line.product_id ? (
//               <>
//                 <Button
//                   title="open in odoo"
//                   link={`https://waboba.odoo.com/web#id=${line.product_id}&action=563&model=product.product&view_type=form&menu_id=222`}
//                   size="xs"
//                   target="_blank"
//                   className="whitespace-nowrap"
//                 />
//                 {line.wis_product ? <Button title="edit" className="whitespace-nowrap" size="xs" onClick={editProduct} /> : null}
//               </>
//             ) : null}
//           </div>
//         </td>
//         <td className="text-right whitespace-nowrap">
//           {line.product_id ? line.bom_qty ? line.bom_qty : <span className="opacity-50 text-xs">No BOM</span> : " - "}
//         </td>
//         <td className={`text-right whitespace-nowrap ${line.product_id ? (line.valid_qty ? "" : "text-red-500") : ""}`}>{line.qty}</td>
//         <td className={`text-right whitespace-nowrap ${line.cbm ? (line.cbm ? "" : "text-red-500") : ""}`}>{line.cbm ? line.cbm.toFixed(3) : "-"}</td>
//         <td className="text-right whitespace-nowrap">{line.ready_date ? moment(line.ready_date).format("YYYY-MM-DD") : null}</td>
//       </tr>
//       {showBom
//         ? line.required_products.map((i, index) => (
//             <tr
//               key={index}
//               className={`text-xs
//                      ${i.in_production ? "opacity-90" : "opacity-50 text-red-500"}
//                      `}
//             >
//               <td className="indent-2">
//                 <div className="flex space-x-4">
//                   <div>- {i.name}</div>
//                   {i.wis_product ? (
//                     i.wis_product.has_production_category ? (
//                       i.in_production ? null : (
//                         <div className="text-red-500 font-semibold">Not in Production.</div>
//                       )
//                     ) : (
//                       <div className="text-red-500 font-semibold">No Production Category.</div>
//                     )
//                   ) : (
//                     <div className="text-red-500 font-semibold">Not connect with wis.</div>
//                   )}
//                 </div>
//               </td>
//               <td></td>
//               <td className="text-right whitespace-nowrap">{i.qty}</td>
//               <td></td>
//               <td className="text-right whitespace-nowrap">{i.production ? formatDate(i.production.ready_date * 1000, "YYYY-MM-DD") : null}</td>
//             </tr>
//           ))
//         : null}
//     </>
//   );
// };

// export default OrderScheduler;
