import { useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { NavigationBar } from "components/base";
import moment from "moment";
import { Link, useParams, useSearchParams } from "react-router-dom";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import Status from "components/Status";
import { FETCH_PRODUCT_SUPPLIER_STOCK } from "./graphql";
import SupplierStockDeliveryForm from "./SupplierStockDeliveryForm";

const ProductStock = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_SUPPLIER_STOCK, {
    variables: { id },
  });
  const modal = useModals();
  const [searchParams] = useSearchParams({ filterSupplierId: "ALL" });
  const filterSupplierId = searchParams.get("filterSupplierId") || "ALL";

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  let rows = [];

  data.product.supplierStocks.forEach((stock) => {
    rows.push({
      id: stock.id,
      type: "STOCK",
      date: stock.readyDate,
      name: `${stock.supplier.name} - Supplier Stock Delivery`,
      qty: stock.qty,
      forecast: stock.latestQty,
      remark: stock.remark,
    });

    stock.usages.forEach((usage) => {
      rows.push({
        type: "USAGE",
        date: usage.plan.readyDate,
        name: usage.plan.name + " - " + usage.plan.odooObject?.objectName,
        qty: -usage.qty,
        state: usage.plan.state,
      });
    });
  });

  rows = rows.sort((a, b) => new Date(a.date) - new Date(b.date));

  for (let i = 0; i < rows.length; i++) {
    // add forecast value to each row.
    if (i === 0) {
      rows[i].forecast = rows[i].qty;
    } else {
      rows[i].forecast = rows[i - 1].forecast + rows[i].qty;
    }
  }
  // Now sort rows by date

  function createStockDelivery() {
    modal.present({
      title: "Create Stock Delivery",
      children: (
        <SupplierStockDeliveryForm
          initData={{
            supplierId: data.product.supplierStocks[0].supplier.id,
            product: data.product,
          }}
          complete={modal.hide}
        />
      ),
    });
  }

  function updateDelivery(row) {
    modal.present({
      title: "Update Stock Delivery",
      children: (
        <SupplierStockDeliveryForm
          id={row.id}
          hide={modal.hide}
          initData={{
            supplierId: data.product.supplierStocks[0].supplier.id,
            product: data.product,
            readyDate: row.date,
            qty: row.qty,
            remark: row.remark,
          }}
          complete={modal.hide}
        />
      ),
    });
  }

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <NavigationBar
        backTo={`./..?filterSupplierId=${filterSupplierId}`}
        title={`[${data.product.number}] ${data.product.name}`}
        rightButtons={
          <CharlesButton onClick={createStockDelivery}>
            + Create Stock Delivery
          </CharlesButton>
        }
      />
      <div className="flex-1 overflow-auto bg-white whitespace-nowrap">
        <table>
          <thead>
            <tr className="sticky top-0 bg-gray-50 z-10">
              <th className="px-6 py-3">Date / 日期</th>
              <th className="px-6">Reference</th>
              <th className="px-6">
                <div className="flex items-center space-x-2">
                  <span>PO / 采购单</span>
                  <div className="relative">
                    <Spinner
                      size={14}
                      className={false ? "opacity-100" : "opacity-0"}
                    />
                  </div>
                </div>
              </th>
              <th className="px-6 text-right">QTY / 数量</th>
              <th className="px-6 text-right">Forecast / 预计剩余数量</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr
                key={index}
                className={`border-y border-gray-100 dark:border-gray-700
                ${row.type === "STOCK" ? "bg-blue-100 font-semibold border-blue-200" : ""}
                `}
              >
                <td className="px-6">
                  {moment(row.date).format("YYYY-MM-DD")}
                </td>
                <td className="px-6 w-full">
                  <div className="flex items-center space-x-2">
                    <div>
                      <div>
                        {row.type === "STOCK" ? (
                          <div className="flex justify-end">
                            <CharlesButton onClick={() => updateDelivery(row)}>
                              {row.name}
                            </CharlesButton>
                          </div>
                        ) : (
                          row.name
                        )}
                      </div>
                      {row.remark && (
                        <div className=" font-normal text-xs">{row.remark}</div>
                      )}
                    </div>
                    {row.type === "USAGE" && <Status status={row.state} />}
                  </div>
                </td>
                <td className="px-6 w-full">{row.po}</td>
                <td className="px-6 text-right">{row.qty}</td>
                <td className="px-6 text-right">{row.forecast}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4"></td>
              <td className="px-6 text-right font-bold text-xl">
                {data.product.supplierStockForecast}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

const StocksView = ({ stocks }) => {
  if (stocks.length === 0) {
    return <div className="text-center text-gray-500">No stocks found.</div>;
  }

  const latestStock = stocks[0];

  const oldStocks = stocks.length > 1 ? stocks.slice(1) : [];

  return (
    <div className="space-y-6">
      <StockUsagesView title="Latest Stock" stock={latestStock} isLatest />

      {oldStocks.map((stock) => (
        <StockUsagesView
          key={stock.id}
          title={`Stock ${moment(stock.updatedAt).format("YYYY-MM-DD")}`}
          stock={stock}
        />
      ))}
    </div>
  );
};

const StockUsagesView = ({ title, stock, isLatest }) => {
  let left = stock.qty;

  const usages = stock.usages
    .filter((i) => i.plan && i.qty > 0)
    .map((i) => {
      left = left - i.qty;
      return { ...i, left };
    });

  return (
    <div className="card p-6">
      <h5>{title}</h5>
      <div className="mt-4">
        {stock.remark && (
          <div className="mt-2">
            <label htmlFor="">Remark: </label>
            <span>{stock.remark}</span>
          </div>
        )}

        {isLatest ? (
          stock.active ? (
            <div className="mt-2">
              If you want to deactivate this stock record, please go to{" "}
              <a
                href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/supplierstockrecord/${stock.id}/change/`}
                target="_blank"
                rel="noreferer"
              >
                Admin
              </a>
              .
            </div>
          ) : (
            <div className="mt-2 font-semibold text-red-600">
              The latest stock record is marked as `NOT active`, it means that
              we are not going to use the supplier stock for this product. Order
              Scheduler plans will not consider this stock record when
              computing. To manage this, please go to{" "}
              <a
                href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/supplierstockrecord/${stock.id}/change/`}
                target="_blank"
                rel="noreferer"
              >
                Admin
              </a>
              .
            </div>
          )
        ) : null}
      </div>
      <div className="-mx-2 mt-4">
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  <h5>
                    Initial QTY at{" "}
                    {moment(stock.readyDate).format("YYYY-MM-DD")}
                  </h5>
                  <div className=" opacity-70">
                    Updated at {moment(stock.updatedAt).format("YYYY-MM-DD")}
                  </div>
                </div>
              </td>
              <th></th>
              <td className="text-right">{stock.qty}</td>
            </tr>
            {usages.length > 0 ? (
              <>
                {usages.map((usage) => (
                  <tr
                    key={usage.id}
                    className="border-t border-gray-100 dark:border-gray-700"
                  >
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <Link
                            to={`/order-scheduler/plans/${usage.plan?.id}`}
                            target="_blank"
                          >
                            {usage.plan?.name}
                          </Link>
                        </div>
                        <Status status={usage.plan?.state} />
                      </div>
                    </td>
                    <td className="text-right">{usage.qty}</td>
                    <td className="text-right">{usage.left}</td>
                  </tr>
                ))}
                <tr className="font-semibold text-base border-t border-gray-100 dark:border-gray-700">
                  <td>Left</td>
                  <td></td>
                  <td className="text-right">{stock.latestQty}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td>No plans using this stock.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductStock;
