import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Input, Text, Switcher, Select } from "components/Form";
import SizeInput from "components/ItemSizeInput";
import { Button } from "components/base";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Alert } from "components/Toast";
import ProductPrice from "components/forms/product/ProductPrice";
import useOdooProductCategories from "hooks/useOdooProductCategories";
import useFactories from "hooks/useFactories";
import { CREATE_ODOO_PRODUCT } from "pages/manage/products/odoo/OdooSyncerForm";
import { FETCH_ALL_PRODUCTS } from "pages/manage/products/All/ProductSelector";
import ProductionSelector from "components/ProductionSelector";
import ProductionCategory from "pages/manage/products/All/ProductionCategory";
import { SAVE_PRODUCT } from "pages/manage/products/All/graphql";

const ConvertProductForm = ({ initialData, onCreated }) => {
  const odooProductQuery = useOdooProductCategories();
  const factoriesQuery = useFactories();

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [description, setDescription] = useState("");

  const [size, setSize] = useState({
    shape: "cube",
    ballDiameter: 0,
    cubeX: 0,
    cubeY: 0,
    cubeZ: 0,
    flatX: 0,
    flatY: 0,
    cylindricalDiameter: 0,
    cylindricalHeight: 0,
  });
  const [itemWeight, setItemWeight] = useState(0);
  const [productionCategoryId, setProductionCategoryId] = useState("0");
  const [productionId, setProductionId] = useState("0");

  const [odooCategoryId, setOdooCategoryId] = useState("0");
  const [defaultSupplierId, setDefaultSupplierId] = useState(null);
  const [prices, setPrices] = useState([]);
  const [isLaborCost, setIsLaborCost] = useState(false);
  const [isSalable, setIsSalable] = useState(false);
  const [isPurchasable, setIsPurchasable] = useState(true);
  const [syncToOdoo, setSyncToOdoo] = useState(true);
  const [createProduct, createProductRes] = useMutation(SAVE_PRODUCT, {
    variables: {
      defaultSupplierId,
      simpleFields: {
        name,
        number,
        itemWeight,
        description,
        isLaborCost,
        isSalable,
        isPurchasable,
        odooCategoryId,
        ...size,
        isPurchasable: true,
      },
      prices,
      productionCategoryId,
      productionId,
    },
    onError: (error) => Alert("error", error.message),
    onCompleted: (res) => {
      Alert("success", "Product Created.");
      if (syncToOdoo) {
        const product = res.saveProduct.product;
        createOdooProduct({
          variables: {
            id: product.id,
            input: {
              name,
              number,
              categId: odooCategoryId,
              weight: itemWeight ? itemWeight / 1000 : 0,
              productType: isLaborCost ? "consu" : "product",
              saleOk: isSalable,
              purchaseOk: isPurchasable,
              hsCode: null,
              barCode: null,
              routes: ["Buy"],
            },
          },
        });
      } else {
        onCreated();
      }
    },
    refetchQueries: [{ query: FETCH_ALL_PRODUCTS }],
  });

  useEffect(() => {
    if (initialData) {
      if (initialData.name) setName(initialData.name);
      if (initialData.prices) setPrices(initialData.prices);
      if (initialData.odooCategoryId) setOdooCategoryId(initialData.odooCategoryId);
      if (initialData.defaultSupplierId) setDefaultSupplierId(initialData.defaultSupplierId);
    }
  }, [initialData]);

  const [createOdooProduct, createOdooProductRes] = useMutation(CREATE_ODOO_PRODUCT, {
    onCompleted: () => {
      Alert("success", "Sync success.");
      onCreated();
    },
    onError: (error) => Alert("error", error.message),
  });

  if (odooProductQuery.loading || factoriesQuery.loading) return <Spinner />;
  if (odooProductQuery.error) return <Errors error={odooProductQuery.error} />;
  if (factoriesQuery.error) return <Errors error={factoriesQuery.error} />;

  function onChangeComponentPrice(index, field, value) {
    setPrices((prev) =>
      prev.map((prevPrice, prevPriceIndex) => {
        if (prevPriceIndex !== index) return prevPrice;
        return { ...prevPrice, [field]: value };
      })
    );
  }

  function validInput() {
    if (name === "") {
      Alert("error", "At least give me a name please..");
      return false;
    }
    if (number === "") {
      Alert("error", "Item number for a product is required. Everything has a number.");
      return false;
    }
    if (defaultSupplierId === "0") {
      Alert("error", "Please choose a default supplier for this product.");
      return false;
    }
    const invalidPrices = prices.filter((i) => i.minQty === "" || i.price === "" || i.factoryId === "0");
    if (invalidPrices.length > 0) {
      Alert("error", "You have invalid price, make sure you input factory, qty and price for each price.");
      return false;
    }
    if (syncToOdoo) {
      if (odooCategoryId === "0") {
        Alert("error", "You must select a odoo category to sync to odoo.");
        return false;
      }
    }
    return true;
  }

  return (
    <div className="flex flex-col flex-1 pb-10">
      <div className="mb-4 flex items-center">
        <label className="mr-4 w-32">Name: </label>
        <Input className="flex-1" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className="mb-4 flex items-center">
        <label className="mr-4 w-32">Item Number: </label>
        <Input className="flex-1" value={number} onChange={(e) => setNumber(e.target.value)} />
      </div>

      <section className="space-x-8 flex items-center mb-4">
        <div className="flex items-center">
          <label className="mr-4 w-32">Is Labor Cost: </label>
          <Switcher isOn={isLaborCost} onChange={() => setIsLaborCost((prev) => !prev)} />
        </div>

        <div className="flex items-center space-x-4">
          <label>Is Salable: </label>
          <Switcher isOn={isSalable} onChange={() => setIsSalable((prev) => !prev)} />
        </div>

        <div className="flex items-center space-x-4">
          <label>Is Purchasable: </label>
          <Switcher isOn={isPurchasable} onChange={() => setIsPurchasable((prev) => !prev)} />
        </div>
      </section>

      {isLaborCost ? null : (
        <>
          <div className="mb-4 flex items-center">
            <label className="mr-4 w-32">Size: </label>
            <SizeInput value={size} onChange={setSize} />
          </div>

          <div className="mb-4 flex items-center space-x-4">
            <label className="w-32">Weight: </label>

            <label className="">Item: </label>
            <Input className="text-center" value={itemWeight} onChange={(e) => setItemWeight(e.target.value)} />
            <span>g</span>
          </div>
        </>
      )}

      <div className="mb-4 flex">
        <label className="w-32 mr-4 mt-4">Description: </label>
        <div className="flex-1">
          <Text placeholder="Materials, craft, model, etc" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
      </div>

      <div className="mt-4 bg-white px-6 py-8 rounded-3xl">
        <h5>Prices</h5>
        <div className="flex-1 space-y-6 mt-4">
          {prices.map((price, index) => (
            <div key={index} className="border-b border-opacity-70 pb-4">
              <ProductPrice
                factories={factoriesQuery.allFactories}
                price={price}
                onChange={(field, value) => onChangeComponentPrice(index, field, value)}
                onDelete={() => setPrices((prev) => prev.filter((_, prevIndex) => prevIndex !== index))}
              />
            </div>
          ))}

          <Button
            title="+ Add Price"
            onClick={() =>
              setPrices((prev) => [
                ...prev,
                {
                  minQty: 0,
                  price: 0,
                  factoryId: "0",
                  avaliableFromMonth: 1,
                  avaliableToMonth: 12,
                },
              ])
            }
          ></Button>
        </div>
      </div>

      <div className="mt-8 rounded-3xl bg-white dark:bg-gray-900 px-8 py-6">
        <h5 className="mb-4">Production</h5>
        <ProductionCategory value={productionCategoryId} onChange={setProductionCategoryId} />
        <ProductionSelector productionId={productionId} onSelect={setProductionId} />
      </div>

      <div className="space-y-4 rounded-3xl bg-white dark:bg-gray-900 px-8 py-6 mt-8">
        <h5>Odoo</h5>
        <div className="flex items-center space-x-4 mt-4">
          <label className="w-32">Create in Odoo:</label>
          <Switcher isOn={syncToOdoo} onChange={() => setSyncToOdoo((prev) => !prev)} />
        </div>

        {syncToOdoo ? (
          <>
            <div className="flex items-baseline space-x-4 mb-4">
              <label className="w-32">Odoo Category: </label>
              <Select value={odooCategoryId || "0"} onChange={(e) => setOdooCategoryId(e.target.value)}>
                <option value="0">Select a Category</option>
                {odooProductQuery.odooProductCategories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.displayName}
                  </option>
                ))}
              </Select>
            </div>
          </>
        ) : null}
      </div>

      <Button
        className="mt-8"
        loading={createProductRes.loading || createOdooProductRes.loading}
        disabled={createProductRes.disabled || createOdooProductRes.loading}
        title="Save"
        bold
        size="xl"
        solid
        onClick={() => {
          if (validInput()) createProduct();
        }}
      />
    </div>
  );
};

export default ConvertProductForm;
