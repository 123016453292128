import { gql } from "@apollo/client";

export const FETCH_PRODUCTS_FOR_OS = gql`
  query FETCH_PRODUCTS_FOR_OS($customerId: ID) {
    products(
      isActive: true
      isSalable: true
      hasOdooId: true
      prefetchAreas: true
      prefetchCost: true
      customerId: $customerId
    ) {
      results {
        id
        odooId
        number
        name
        quantityPerCarton
        cbm: outerCartonCbm
        areas {
          id
        }
        cost
      }
    }
  }
`;

export const FETCH_PREV_PLANS = gql`
  query FETCH_PREV_PLANS(
    $statesIn: [String]
    $options: CommonOptionsInput
    $rootPlans: Boolean
  ) {
    allProductionPlans(
      statesIn: $statesIn
      options: $options
      rootPlans: $rootPlans
    ) {
      results {
        id
        name
        wisCustomer {
          id
          name
        }
        startDate
        readyDate
        state
        odooObject {
          objectType
          objectId
          objectName
        }
        createdBy {
          firstName
        }
        handledBy {
          firstName
        }
      }
      total
    }
  }
`;

const PRODUCTION_PLAN_FRAGMENT = gql`
  fragment productionPlan on ProductionPlanType {
    id
    latestPlanId
    parent {
      id
      name
      totalCbm
      children {
        id
        name
        totalCbm
      }
    }
    name
    wisCustomer {
      id
      name
      odooPartnerId
    }
    wisPriceList {
      id
    }
    customer
    startDate
    readyDate
    finalReadyDate
    lines {
      id
      name
      product {
        id
        number
        name
        odooId
        quantityPerCarton
        cbm: outerCartonCbm
        isActive
        considerBomInProduction
        children {
          id
        }
      }
      productOdooId
      qty
      reduceQty
      finalQty
      salesPrice
      discountPercentage
      totalPrice
      totalCbm
      unitCost
      totalCost
      readyDate
    }
    planType
    orderType
    state
    odooObject {
      id
      objectId
      objectName
      objectType
    }
    currency
    totalQty
    productCost
    totalCbm
    shippingCost
    totalCost
    totalPrice
    finalTotalQty
    finalTotalCbm
    finalTotalCost
    finalTotalPrice
    joinQueueAt
    requestConfirmAt
    confirmTimeAt
    confirmCostAt
    handleAt
    cancelAt
    odooTransferOperationTypeId
    odooTransferFromLocationId
    odooTransferToLocationId
    odooSalesOrderWarehouseId
    odooSalesOrderPriceListId
    odooSalesOrderPaymentTermsId
    odooTaxId
    odooSalesOrderClientOrderRef
    odooIncoterm
    odooXDeliverymethod
    autoSendToOdoo
    description
  }
`;

export const FETCH_PRODUCTION_PLAN = gql`
  query FETCH_PRODUCTION_PLAN($id: ID!) {
    productionPlan(id: $id) {
      ...productionPlan
      children {
        id
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const COMPUTE_PRODUCTION_PLAN = gql`
  mutation COMPUTE_PRODUCTION_PLAN($id: ID!) {
    computeProductionPlan(id: $id) {
      productionPlan {
        ...productionPlan
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const CREATE_PRODUCTION_PLAN = gql`
  mutation CREATE_PRODUCTION_PLAN(
    $parentId: ID
    $name: String!
    $customer: String
    $planType: String
    $orderType: String
    $startDate: DateTime
    $autoSendToOdoo: Boolean
  ) {
    createProductionPlan(
      parentId: $parentId
      name: $name
      customer: $customer
      planType: $planType
      orderType: $orderType
      startDate: $startDate
      autoSendToOdoo: $autoSendToOdoo
    ) {
      productionPlan {
        ...productionPlan
        parent {
          id
          ...productionPlan
          children {
            ...productionPlan
          }
        }
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const SAVE_PRODUCTION_PLAN = gql`
  mutation SAVE_PRODUCTION_PLAN(
    $id: ID!
    $name: String
    $startDate: DateTime
    $wisCustomerId: ID
    $wisPriceListId: ID
    $lines: [ProductionPlanLineInputType]
    $shippingCost: Float
    $currency: String
    $planType: String
    $orderType: String
    $odooTransferOperationTypeId: Int
    $odooTransferFromLocationId: Int
    $odooTransferToLocationId: Int
    $odooSalesOrderWarehouseId: Int
    $odooSalesOrderPriceListId: Int
    $odooSalesOrderPaymentTermsId: Int
    $odooTaxId: Int
    $odooSalesOrderClientOrderRef: String
    $odooIncoterm: Int
    $odooXDeliverymethod: Int
    $autoSendToOdoo: Boolean
    $description: String
  ) {
    saveProductionPlan(
      id: $id
      name: $name
      startDate: $startDate
      wisCustomerId: $wisCustomerId
      wisPriceListId: $wisPriceListId
      lines: $lines
      shippingCost: $shippingCost
      currency: $currency
      planType: $planType
      orderType: $orderType
      odooTransferOperationTypeId: $odooTransferOperationTypeId
      odooTransferFromLocationId: $odooTransferFromLocationId
      odooTransferToLocationId: $odooTransferToLocationId
      odooSalesOrderWarehouseId: $odooSalesOrderWarehouseId
      odooSalesOrderPriceListId: $odooSalesOrderPriceListId
      odooSalesOrderPaymentTermsId: $odooSalesOrderPaymentTermsId
      odooTaxId: $odooTaxId
      odooSalesOrderClientOrderRef: $odooSalesOrderClientOrderRef
      odooIncoterm: $odooIncoterm
      odooXDeliverymethod: $odooXDeliverymethod
      autoSendToOdoo: $autoSendToOdoo
      description: $description
    ) {
      productionPlan {
        ...productionPlan
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const UPDATE_PRODUCTION_PLAN_STATE = gql`
  mutation UPDATE_PRODUCTION_PLAN_STATE($id: ID!, $action: String!) {
    updateProductionPlanState(id: $id, action: $action) {
      productionPlan {
        id
        state
        requestConfirmAt
        confirmTimeAt
        confirmCostAt
        handleAt
        cancelAt
        odooObject {
          id
          objectId
          objectName
          objectType
        }
        parent {
          id
          finalTotalQty
          finalTotalCbm
          finalTotalCost
          finalTotalPrice
          finalReadyDate
        }
      }
    }
  }
`;

export const DELETE_PRODUCTION_PLAN = gql`
  mutation DELETE_PRODUCTION_PLAN($id: ID!) {
    deleteProductionPlan(id: $id) {
      deleted
    }
  }
`;

export const FETCH_PRICE_LIST_DETAIL = gql`
  query FETCH_PRICE_LIST_DETAIL($id: ID!) {
    salesPrices(priceListIds: [$id], prefetchProductCost: true) {
      id
      product {
        id
      }
      discountPercentage
      price: finalPrice
      state
    }
  }
`;

export const FETCH_PRODUCT_PRODUCTION_INFO = gql`
  query FETCH_PRODUCT_PRODUCTION_INFO($id: ID!) {
    product(id: $id) {
      id
      considerBomInProduction
      production {
        id
        descendants {
          category {
            id
          }
        }
      }
      productionCategory {
        id
        name
        factory {
          id
          name
        }
      }
      children {
        id
        product {
          id
          number
          name
          productionCategory {
            id
            name
            factory {
              id
              name
            }
          }
        }
        qty
      }
    }
  }
`;

export const FETCH_PLAN_SUMMARY = gql`
  query FETCH_PLAN_SUMMARY($id: ID!) {
    productionPlan(id: $id) {
      id
      orderType
      lines {
        id
        product {
          id
          name
          number
          quantityPerCarton
        }
        unitCost
        totalCost
        finalQty
        totalCbm
        salesPrice
        totalPrice
        readyDate
      }
      children(statesIn: ["DRAF", "WAITING_TO_CONFIRM", "HANDLED"]) {
        id
        state
        lines {
          product {
            id
            name
            number
            quantityPerCarton
          }
          unitCost
          totalCost
          finalQty
          totalCbm
          salesPrice
          totalPrice
          readyDate
        }
      }
      finalTotalQty
      finalTotalCbm
      finalTotalCost
      finalTotalPrice
      finalReadyDate
    }
  }
`;

export const planSummaryFragment = gql`
  fragment planSummaryFragment on ProductionPlanType {
    id
    lines {
      id
      product {
        id
        name
        number
        quantityPerCarton
      }
      unitCost
      totalCost
      finalQty
      totalCbm
      totalPrice
      readyDate
    }
    children(statesIn: ["HANDLED"]) {
      id
      state
      lines {
        product {
          id
          name
          number
          quantityPerCarton
        }
        unitCost
        totalCost
        finalQty
        totalCbm
        totalPrice
        readyDate
      }
    }
    finalTotalQty
    finalTotalCbm
    finalTotalCost
    finalTotalPrice
    finalReadyDate
  }
`;

export const FETCH_ODOO_INTERNAL_TRANSFER = gql`
  query FETCH_ODOO_INTERNAL_TRANSFER($ids: [Int]!, $planId: ID!) {
    odooObjectDetail: odooInternalTransferDetail(ids: $ids) {
      id
      name
      lines: moves {
        id
        name
        product {
          id
          name
          number
        }
        qty
      }
    }
    productionPlan(id: $planId) {
      ...planSummaryFragment
    }
  }
  ${planSummaryFragment}
`;

export const FETCH_ODOO_SALES_ORDER_DETAIL = gql`
  query FETCH_ODOO_SALES_ORDER_DETAIL($ids: [Int]!, $planId: ID!) {
    odooObjectDetail: odooSoDetail(ids: $ids) {
      id
      name
      lines: orderLines {
        id
        name
        product {
          id
          number
          name
        }
        qty: productUomQty
      }
    }
    productionPlan(id: $planId) {
      ...planSummaryFragment
    }
  }
  ${planSummaryFragment}
`;

export const UPDATE_PRODUCTION_PLAN_TO_ODOO = gql`
  mutation UPDATE_PRODUCTION_PLAN_TO_ODOO(
    $planId: ID!
    $odooObjectId: Int!
    $odooObjectType: String!
  ) {
    updateProductionPlanToOdoo(
      planId: $planId
      odooObjectId: $odooObjectId
      odooObjectType: $odooObjectType
    ) {
      productionPlan {
        id
      }
    }
  }
`;
